(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/wager-payment-flow/standard-flow/assets/javascripts/standard-payment-flow.js') >= 0) return;  svs.modules.push('/components/wager-payment-flow/standard-flow/assets/javascripts/standard-payment-flow.js');
"use strict";


const {
  createPayment
} = svs.components.payment.payment;
const {
  grassroots
} = svs.components.payment.middleware;
const {
  authenticated
} = svs.components.payment.middleware.authenticated;
const {
  accountBalanceMW,
  competitionMW,
  spelpausMW,
  paymentConfirmMw,
  paymentDoneMW,
  timeLimitsMW
} = svs.components.wagerPaymentFlow.standardFlow.middlewareSetup;
const {
  WagerService
} = svs.components.payment.serviceWager;

const createStandardPaymentFlow = _ref => {
  let {
    config,
    wagers,
    extraPreMW = [],
    extraPostMW = [],
    callback
  } = _ref;
  const paymentBuilder = createPayment(new WagerService(config)).addPayable(...wagers).addPreMiddleware(authenticated).addPreMiddleware(spelpausMW).addPreMiddleware(timeLimitsMW).addPreMiddleware(accountBalanceMW).addPreMiddleware(competitionMW);
  if (extraPreMW.length > 0) {
    extraPreMW.forEach(preMW => paymentBuilder.addPreMiddleware(preMW));
  }
  paymentBuilder.addPreMiddleware(paymentConfirmMw(callback));
  paymentBuilder.addPostMiddleware(paymentDoneMW);
  if (extraPostMW.length > 0) {
    extraPostMW.forEach(postMW => paymentBuilder.addPostMiddleware(postMW));
  }
  paymentBuilder.addPostMiddleware(grassroots);
  return paymentBuilder;
};
setGlobal('svs.components.wagerPaymentFlow.standardFlow.createStandardPaymentFlow', createStandardPaymentFlow);

 })(window);